/* eslint-disable no-restricted-syntax */
import Aos from 'aos';
import 'aos/dist/aos.css';
import window from 'global';
import * as React from 'react';
import MyCarousel from '../components/MyCarousel';
import MyCarouselSlide from '../components/CarouselSlide';
import Footer from '../components/Footer';
import Head from '../components/Head';
import HoverHighlightText from '../components/HoverHighlightText';
import MainSubtitle from '../components/MainSubtitle';
import MainTitle from '../components/MainTitle';
import MobileNavbar from '../components/MobileNav';
import ModuleCard from '../components/ModuleCard';
import Navbar from '../components/NavBar';
import Section from '../components/Section';
import SectionContentContainer from '../components/SectionContentContainer';
import UpButton from '../components/UpButton';
import nexgenAdvProgramming from '../images/nexgen-advanced-programming.webp';
import nexgenAlgBgImg from '../images/nexgen-algorithm-and-data-structure.webp';
import nexgenCoreProgramming from '../images/nexgen-core-programming.webp';
import nexgenCourseBgImg from '../images/nexgen-course-overview.webp';
import nexgenDataBgImg from '../images/nexgen-data-management.webp';
import nexgenBgImg from '../images/nexgen-learn-coding.webp';
import nexgenNetBgImg from '../images/nexgen-network-and-application.webp';
import nexgenOverviewBgImg from '../images/nexgen-overview.webp';
import { hideCarousel } from '../utils';

const NexgenPage = () => {
  const breakpoint = 991;
  const [dimension, setDimension] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    Aos.init();
    Aos.refresh();
    function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Head pageTitle="NexGen Computing" />
      {dimension.width > breakpoint ? <Navbar /> : <MobileNavbar />}
      <Section backgroundImage={nexgenBgImg} customStyle={{ textAlign: 'center' }}>
        <SectionContentContainer customStyle={{ left: 0 }}>
          {dimension.width > breakpoint ? (
            <>
              {' '}
              <MainTitle text="NEXGEN COMPUTING" style={{ fontSize: 'calc(3vw + 3vh)' }} />
              <br />
              <MainSubtitle text="ACE SCHOOL, ACE LIFE" style={{ fontSize: 'calc(1.2vw + 1.2vh)' }} />
            </>
          ) : (
            <>
              {' '}
              <MainTitle text="NEXGEN COMPUTING" />
              <br />
              <MainSubtitle text="ACE SCHOOL, ACE LIFE" />
            </>
          )}

        </SectionContentContainer>
      </Section>
      <Section backgroundImage={nexgenOverviewBgImg}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer className="col-4" customStyle={{ position: 'relative', padding: '10vh 0', textAlign: 'right' }}>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="ABOUT" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="NexGen Computing course covers GCE computing courses (O’s & A’s) and develops the actual skillsets required to transform theory into reality"
              />
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="THEORY APPLIED" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Academia is limited in many aspects but covers important concepts. At TechBoss, we guide our students to understanding of the concepts and train them in the critical skillsets"
              />
            </div>
            <br />
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="At the end of our course, students will grasp both the theory needed to ace school examinations and attain the skills to succeed"
              />
            </div>
          </SectionContentContainer>
        ) : (
          <SectionContentContainer customStyle={{ position: 'relative', padding: '10vh 0' }}>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="ABOUT" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="NexGen Computing course covers GCE computing courses (O’s & A’s) and develops the actual skillsets required to transform theory into reality"
              />
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="THEORY APPLIED" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Academia is limited in many aspects but covers important concepts. At TechBoss, we guide our students to understanding of the concepts and train them in the critical skillsets"
              />
            </div>
            <br />
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="At the end of our course, students will grasp both the theory needed to ace school examinations and attain the skills to succeed"
              />
            </div>
          </SectionContentContainer>
        )}
      </Section>
      <Section id="modules-container" customStyle={{ backgroundColor: 'black' }}>
        {dimension.width > breakpoint ? (
          <>
            <div style={{
              display: 'flex', justifyContent: 'center', position: 'absolute', top: '20%', width: '100%',
            }}
            >
              <ModuleCard
                moduleName="CORE PROGRAMMING"
                backgroundImage={nexgenCoreProgramming}
                onClick={() => {
                  hideCarousel();
                  document.getElementById('nexgenCore').style.display = 'block';
                  document.getElementById('nexgenCore').scrollIntoView({ behavior: 'smooth' });
                }}
              />
              <ModuleCard
                moduleName="ADVANCED PROGRAMMING"
                backgroundImage={nexgenAdvProgramming}
                onClick={() => {
                  hideCarousel();
                  document.getElementById('nexgenAdv').style.display = 'block';
                  document.getElementById('nexgenAdv').scrollIntoView({ behavior: 'smooth' });
                }}
              />
            </div>
            <div style={{
              display: 'flex', justifyContent: 'center', position: 'absolute', bottom: '20%', width: '100%', padding: '0 3vw',
            }}
            >
              <ModuleCard
                moduleName="ALGORITHM"
                backgroundImage={nexgenAlgBgImg}
                onClick={() => {
                  hideCarousel();
                  document.getElementById('nexgenAlg').style.display = 'block';
                  document.getElementById('nexgenAlg').scrollIntoView({ behavior: 'smooth' });
                }}
              />
              <ModuleCard
                moduleName="DATA MANAGEMENT"
                backgroundImage={nexgenDataBgImg}
                onClick={() => {
                  hideCarousel();
                  document.getElementById('nexgenData').style.display = 'block';
                  document.getElementById('nexgenData').scrollIntoView({ behavior: 'smooth' });
                }}
              />
              <ModuleCard
                moduleName="NETWORK & APPLICATION"
                backgroundImage={nexgenNetBgImg}
                onClick={() => {
                  hideCarousel();
                  document.getElementById('nexgenNetwork').style.display = 'block';
                  document.getElementById('nexgenNetwork').scrollIntoView({ behavior: 'smooth' });
                }}
              />
            </div>
          </>
        ) : (
          <>
            <ModuleCard
              moduleName="CORE PROGRAMMING"
              backgroundImage={nexgenCoreProgramming}
              onClick={() => {
                hideCarousel();
                document.getElementById('nexgenCore').style.display = 'block';
                document.getElementById('nexgenCore').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="ADVANCED PROGRAMMING"
              backgroundImage={nexgenAdvProgramming}
              onClick={() => {
                hideCarousel();
                document.getElementById('nexgenAdv').style.display = 'block';
                document.getElementById('nexgenAdv').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="ALGORITHM"
              backgroundImage={nexgenAlgBgImg}
              onClick={() => {
                hideCarousel();
                document.getElementById('nexgenAlg').style.display = 'block';
                document.getElementById('nexgenAlg').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="DATA MANAGEMENT"
              backgroundImage={nexgenDataBgImg}
              onClick={() => {
                hideCarousel();
                document.getElementById('nexgenData').style.display = 'block';
                document.getElementById('nexgenData').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="NETWORK & APPLICATION"
              backgroundImage={nexgenNetBgImg}
              onClick={() => {
                hideCarousel();
                document.getElementById('nexgenNetwork').style.display = 'block';
                document.getElementById('nexgenNetwork').scrollIntoView({ behavior: 'smooth' });
              }}
            />
          </>
        )}

      </Section>

      {/*
      *
      * NEXGEN CORE PROGRAMMING CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="nexgenCore"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={nexgenCoreProgramming}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="CORE PROGRAMMING" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="This course covers following chapters of the A-level curriculum (from A-level curric)" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={nexgenCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '25%', left: '15%' }}>
                  <HoverHighlightText highlightText="15" normalText="lessons" />
                  <div style={{ display: 'flex' }}>
                    <HoverHighlightText highlightText="12" normalText="theory lessons + " />
                    <HoverHighlightText highlightText="2" normalText="practice sessions + " />
                    <HoverHighlightText highlightText="1" normalText="hands-on project" />
                  </div>
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="$70" normalText="per hour" />
                  <HoverHighlightText highlightText="2:5" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '20%', left: '10%' }}>
                  <HoverHighlightText highlightText="15" normalText="lessons" />
                  <div>
                    <HoverHighlightText highlightText="12" normalText="theory lessons + " />
                    <HoverHighlightText highlightText="2" normalText="practice sessions + " />
                    <HoverHighlightText highlightText="1" normalText="hands-on project" />
                  </div>
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="$70" normalText="per hour" />
                  <HoverHighlightText highlightText="2:5" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Standard Data Types and Operators" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="String manipulation" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Conditional Statements" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Sequential Collections" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Iterations" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Non-sequential Collections" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Modularity" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Application Building" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * NEXGEN ADVANCED PROGRAMMING CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="nexgenAdv"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={nexgenAdvProgramming}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="ADVANCED PROGRAMMING" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="This course covers following chapters of the A-level curriculum (from A-level curric)" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={nexgenCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '25%', left: '15%' }}>
                  <HoverHighlightText highlightText="15" normalText="lessons" />
                  <div style={{ display: 'flex' }}>
                    <HoverHighlightText highlightText="12" normalText="theory lessons + " />
                    <HoverHighlightText highlightText="2" normalText="practice sessions + " />
                    <HoverHighlightText highlightText="1" normalText="hands-on project" />
                  </div>
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="$70" normalText="per hour" />
                  <HoverHighlightText highlightText="2:5" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '20%', left: '10%' }}>
                  <HoverHighlightText highlightText="15" normalText="lessons" />
                  <div>
                    <HoverHighlightText highlightText="12" normalText="theory lessons + " />
                    <HoverHighlightText highlightText="2" normalText="practice sessions + " />
                    <HoverHighlightText highlightText="1" normalText="hands-on project" />
                  </div>
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="$70" normalText="per hour" />
                  <HoverHighlightText highlightText="2:5" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Shorthand Statements" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="File Processing" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Memory Allocation" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Class & Objects" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Abstraction & Encapsulation" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Inheritance" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Polymorphism" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Testing & Debugging" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * NEXGEN ALGORITHM AND DATA STRUCTURE CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="nexgenAlg"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={nexgenAlgBgImg}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="ALGORITHM AND DATA STRUCTURE" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Basic understanding of Algorithms, and some commonly used Data structure" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={nexgenCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '25%', left: '15%' }}>
                  <HoverHighlightText highlightText="15" normalText="lessons" />
                  <div style={{ display: 'flex' }}>
                    <HoverHighlightText highlightText="12" normalText="theory lessons + " />
                    <HoverHighlightText highlightText="2" normalText="practice sessions + " />
                    <HoverHighlightText highlightText="1" normalText="hands-on project" />
                  </div>
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="$70" normalText="per hour" />
                  <HoverHighlightText highlightText="2:5" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '20%', left: '10%' }}>
                  <HoverHighlightText highlightText="15" normalText="lessons" />
                  <div>
                    <HoverHighlightText highlightText="12" normalText="theory lessons + " />
                    <HoverHighlightText highlightText="2" normalText="practice sessions + " />
                    <HoverHighlightText highlightText="1" normalText="hands-on project" />
                  </div>
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="$70" normalText="per hour" />
                  <HoverHighlightText highlightText="2:5" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Complexity" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Bubble & Insertion sort" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Quicksort" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Merge sort" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Linear & Binary search" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Hash table" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Stack & Queue" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Linked list" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="BST & traversal" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * NEXGEN DATA MANAGEMENT CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="nexgenData"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={nexgenDataBgImg}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="DATA MANAGEMENT" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Basic understanding of DBMS, SQL, NoSQL, Data normalization, interacting with the database from a program" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={nexgenCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '25%', left: '15%' }}>
                  <HoverHighlightText highlightText="15" normalText="lessons" />
                  <div style={{ display: 'flex' }}>
                    <HoverHighlightText highlightText="12" normalText="theory lessons + " />
                    <HoverHighlightText highlightText="2" normalText="practice sessions + " />
                    <HoverHighlightText highlightText="1" normalText="hands-on project" />
                  </div>
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="$70" normalText="per hour" />
                  <HoverHighlightText highlightText="2:5" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '20%', left: '10%' }}>
                  <HoverHighlightText highlightText="15" normalText="lessons" />
                  <div>
                    <HoverHighlightText highlightText="12" normalText="theory lessons + " />
                    <HoverHighlightText highlightText="2" normalText="practice sessions + " />
                    <HoverHighlightText highlightText="1" normalText="hands-on project" />
                  </div>
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="$70" normalText="per hour" />
                  <HoverHighlightText highlightText="2:5" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Database Introduction" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="DB & ER Diagram" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="SQL Fundamental" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="CRUD Tables Operation" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="SQL Advanced" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="NoSQL Intro" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Data Security" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * NEXGEN NETWORK & APPLICATION CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="nexgenNetwork"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={nexgenNetBgImg}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="NETWORKING & APPLICATION" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Computer Network fundamentals (LAN, WAN, The Internet, DNS, Protocols, Server-client architecture), web application, Network security" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={nexgenCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '25%', left: '15%' }}>
                  <HoverHighlightText highlightText="15" normalText="lessons" />
                  <div style={{ display: 'flex' }}>
                    <HoverHighlightText highlightText="12" normalText="theory lessons + " />
                    <HoverHighlightText highlightText="2" normalText="practice sessions + " />
                    <HoverHighlightText highlightText="1" normalText="hands-on project" />
                  </div>
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="$70" normalText="per hour" />
                  <HoverHighlightText highlightText="2:5" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '20%', left: '10%' }}>
                  <HoverHighlightText highlightText="15" normalText="lessons" />
                  <div>
                    <HoverHighlightText highlightText="12" normalText="theory lessons + " />
                    <HoverHighlightText highlightText="2" normalText="practice sessions + " />
                    <HoverHighlightText highlightText="1" normalText="hands-on project" />
                  </div>
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="$70" normalText="per hour" />
                  <HoverHighlightText highlightText="2:5" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="HTML and CSS" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Bootstrap" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Flask Basics" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Network Fundamentals" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Protocol & Network Architecture" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Network Security" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />
      <UpButton />
      <Footer />
    </>
  );
};

export default NexgenPage;
